import './mainPage.css';
import IntroVideo from '../Assets/IntroVideo.gif';
import {Translator, Translate} from "react-auto-translate";

const data = {
  "h1": "Creating Cartoon Videos Now Became Easier And Simpler",
  "p": "Create Cartoon Videos With India's Most Loved Animation App",
  "download": "Get the App",
  "tutbtn": "Watch tutorial"
}

function mainPage(Languages) {
  const cacheProvider = {
    get: (language, key) =>
      ((JSON.parse(localStorage.getItem("translations")) || {})[key] || {})[
      language
      ],
    set: (language, key, value) => {
      const existing = JSON.parse(localStorage.getItem("translations")) || {
        [key]: {},
      };
      existing[key] = {...existing[key], [language]: value};
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };
  return (
    <Translator
      cacheProvider={cacheProvider}
      from={"en"}
      to={Languages.Languages}
      googleApiKey="AIzaSyCGf-6SQug5ldGlhOB-HBnm6x29xGg2Irk"
    >
      <div className="">
        <section className='section cl1'>
          <div className='main'>
            <div className='mainLeft'>
              <h1><Translate>{data["h1"]}</Translate></h1>
              <p><Translate>{data["p"]}</Translate></p>
              <div>
                <button className='download' onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.kk.chimpoon&hl=en_IN&gl=US"
                  )
                }><span><Translate>{data["download"]}</Translate> <i className='fa fa-arrow-circle-right'></i></span></button>
                <button className="tutbtn" onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCQP0C_4_xlA-bvZGxEwxnGg/videos"
                  )
                }><Translate>{data["tutbtn"]}</Translate></button>
              </div>

            </div>
            <div className='mainRight'>
              <img src={IntroVideo} alt="" />
            </div>
          </div>
        </section>
      </div>
    </Translator>
  );
}

export default mainPage;